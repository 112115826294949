/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/38bf21");

// $body-bg: #e4e5e6;
// $body-color: #23282c;

// // Assumes the browser default, typically `16px`
// $font-size-base: 0.8rem;

// // RoundPoint blue
// //$blue: #40B4E2;

// Re-define the blue color
$blue: #0072c6;

// Override default H*
$headings-line-height: 2;
$headings-color: $blue;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// Responsive default font size
html {
  font-size: 0.8rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 0.9rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.1rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-transform: uppercase;
}

#content {
  background-color: #fff;
}

#nav {
  font-size: 0.9rem;
}

#footer {
  margin-top: 50px;
  background: #252b2d;
  color: #fff;
}
#footer a {
  color: #fff;
}

.sign-in-link {
  color: #55c6b4 !important;
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("webfonts/38BF21_0_0.eot");
  src: url("webfonts/38BF21_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/38BF21_0_0.woff2") format("woff2"),
    url("webfonts/38BF21_0_0.woff") format("woff"),
    url("webfonts/38BF21_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("webfonts/38BF21_1_0.eot");
  src: url("webfonts/38BF21_1_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/38BF21_1_0.woff2") format("woff2"),
    url("webfonts/38BF21_1_0.woff") format("woff"),
    url("webfonts/38BF21_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("webfonts/38BF21_2_0.eot");
  src: url("webfonts/38BF21_2_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/38BF21_2_0.woff2") format("woff2"),
    url("webfonts/38BF21_2_0.woff") format("woff"),
    url("webfonts/38BF21_2_0.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("webfonts/38BF21_3_0.eot");
  src: url("webfonts/38BF21_3_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/38BF21_3_0.woff2") format("woff2"),
    url("webfonts/38BF21_3_0.woff") format("woff"),
    url("webfonts/38BF21_3_0.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("webfonts/38BF21_4_0.eot");
  src: url("webfonts/38BF21_4_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/38BF21_4_0.woff2") format("woff2"),
    url("webfonts/38BF21_4_0.woff") format("woff"),
    url("webfonts/38BF21_4_0.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Black";
  src: url("webfonts/38BF21_5_0.eot");
  src: url("webfonts/38BF21_5_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/38BF21_5_0.woff2") format("woff2"),
    url("webfonts/38BF21_5_0.woff") format("woff"),
    url("webfonts/38BF21_5_0.ttf") format("truetype");
}
